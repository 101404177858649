@import "vars_aurelio";


.pagination {
    width: 100%;
    margin: 0 0 10px 0;

    .page-item:after {
        padding: 0 5px !important;
        font-family: "Font Awesome 5 Pro";
        content: "\f0da";
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        font-weight: 800;
        color: $semi-light-background-color;
    }

    .page-item-container {
        display: inline-block;
        border-radius: 30px;
        padding: 1px;
        background: linear-gradient(147.45deg, $main-color 19.48%, $logo-secondary-color 86.75%);
    }

    .page-item .page-link {
        border-radius: 29px !important;
        color: #858a90;
        background: #fff;
        padding: 12px 20px;
        display: inline-flex;
    }


    .page-item-container:focus,
    .page-item-container:hover,
    .page-item-container.hover {
        background: linear-gradient(147.45deg, darken($main-color, 10%) 19.48%, darken($logo-secondary-color, 10%) 86.75%);
    }

    .page-item .page-link:focus,
    .page-item .page-link:hover,
    .page-item .page-link.hover {
        color: darken($background-light, 60%);
        background-color: #fff;
    }

    .last-item a.page-link {
        font-weight: 500;
    }
}

.pagination.pagination-small {
    width: 100%;
    padding: 10px 4px;
    margin: 0 0 10px 0;
    background-color: transparent;

    .page-item-container {
        border-radius: 15px;
        background: transparent !important;
    }

    .page-item .page-link {
        border-radius: 14px !important;
        background-color: transparent;
        padding: 0;
    }
}

.nx-booking.booking .pagination .page-item-container {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    border-radius: 10px;
    text-align: center;
}

.nx-booking.booking .pagination .page-item.page-item:after {
    display: none;
}

.nx-booking.booking .pagination .page-item .page-link {
    display: block;
    width: 100%;
    text-align: center;
    border-radius: 9px !important;
    padding: 8px 20px;
}

.pagination .page-item:after,
.pagination-small .page-item:after {
    padding: 0 5px !important;
    font-family: "Font Awesome 5 Pro";
    content: "\f0da";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 800;
    color: #cad0d4;
}

.pagination .page-item:last-of-type:after,
.pagination-small .page-item:last-of-type:after {
    display: none;
}

.pagination .page-item-container.active {
    background: linear-gradient(147.45deg, lighten($main-color, 15%) 19.48%, lighten($logo-secondary-color, 15%) 86.75%);

}

.pagination .page-item-container.disabled,
.pagination .page-item-container.disabled:hover {
    opacity: .6 !important;
    background: linear-gradient(147.45deg, $main-color 19.48%, $logo-secondary-color 86.75%);
}

.pagination .page-item-container.disabled .page-link {
    cursor: default;
}

.pagination .page-item-container.active:hover,
.pagination .page-item-container.active:focus {
    background: linear-gradient(147.45deg, lighten($main-color, 10%) 19.48%, lighten($logo-secondary-color, 10%) 86.75%);
}

.pagination .page-item-container.active .page-link {
    background: linear-gradient(147.45deg, lighten($main-color, 10%) 19.48%, lighten($logo-secondary-color, 10%) 86.75%);
    cursor: default;
    font-weight: 600;
    color: #fafbfc;
}

.context-club-button {
    position: absolute;
    left: 0;
    padding: 0;
    margin-top: -7px;
    z-index: 9999;

    div.text {
        display: block;
        float: right;

        span {
            display: block;
        }
    }
}

.context-club {
    font-size: 20px;
    color: $main-color;
}

.club-link {
    position: relative;

    .btn-info a {
        color: $dark-text-color;
        line-height: 1em;
    }
}

.breadcrumbs-dropdown {
    width: 50vw !important;
    margin-left: 25vw;

    a {
        padding-left: 10px;
    }

    li:first-child {
        display:none;
    }
}

@media only screen and (max-width: 767px) {
    .pagination .page-item .page-link {
        border-radius: 9px !important;
    }

    .pagination .page-item-container {
        border-radius: 10px;
    }

    .pagination {
        margin: 0 !important;
    }

    .pagination:not(.pagination-small) .page-item-container,
    .pagination:not(.pagination-small) .page-item .page-link {
        width: 100%;
        text-align: center;
        display: block !important;
    }

    .pagination .page-item-container {
        margin-bottom: 5px;
    }

    .pagination:not(.pagination-small) .page-item.page-item:after {
        display: none;
    }

    .context-club-button {
        position: relative;
        width: 100%;
        padding: 0;
        margin-bottom: 10px;

        .btn.btn-info {
            width: 100%;

            img {
                margin: 0 !important;
                height: 40px;
            }
        }
    }

    .flexbox {
        flex-direction: column !important;
        padding: 0 11px;
    }

    .breadcrumbs {
        order: 2;
    }

    .club-link {
        order: 1;
    }

    .pagination.pagination-small {
        padding: 10px 0;
    }
}

@media only screen and (max-width: 991px) {
    .breadcrumbs-lg {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .breadcrumbs-xs {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .breadcrumbs-dropdown {
        width: 75vw !important;
        margin-left: 15vw;

        li:first-child {
            display: block;
        }
    }    
}